import React from 'react'

import {Grid, Row, Col} from 'react-flexbox-grid'

import DotBackground from 'components/common/DotBackground'

import Form from './Form'
import InfoBox from './InfoBox'

const RegisterPanel = () => (
  <DotBackground>
    <Grid>
      <Row middle='xs' around='xs'>
        <Col md={6}>
          <InfoBox />
        </Col>
        <Col md={5} mdOffset={1}>
          <Form />
        </Col>
      </Row>
    </Grid>
  </DotBackground>
)

export default RegisterPanel
