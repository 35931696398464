import React from 'react'

import {Medium} from 'components/common/Responsive'

const InfoBox = () => (
  <Medium>
    {matches => (
      <div style={matches ? {} : {textAlign: 'center'}}>
        <h1 style={{
          fontSize: 64,
          fontWeight: 500,
          lineHeight: 1
        }}
        >
          We enable great Telecoms
        </h1>
        <p style={{
          fontSize: 21
        }}>
          Clouway is a software company specialized in software development of enterprise applications. We believe in
          great applications as a solution to make our life easier.
        </p>
      </div>
    )}
  </Medium>
)

export default InfoBox
