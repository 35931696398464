import React from 'react'
import PropTypes from 'prop-types'

import * as Yup from 'yup'
import http from 'http-client'
import {Formik} from 'formik'
import {withNamespaces} from 'react-i18next'

import Alert from 'components/common/Alert'
import Input from 'components/common/Input'
import Label from 'components/common/Label'
import ReCAPTCHA from 'components/common/ReCAPTCHA'
import FormGroup from 'components/common/FormGroup'
import SubmitButton from 'components/common/SubmitButton'

import classes from './Form.module.css'

class Form extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)

    this.recaptchaRef = React.createRef()

    this.state = {}
  }

  handleSubmit = (values, actions) => {
    this.setState({values, actions})
    this.recaptchaRef.current.execute()
  }

  handleCaptchaChange = captchaResponse => {
    const {values, actions} = this.state

    http.post(`/customers?captchaResponse=${captchaResponse}`, values)
      .then(() => {
        actions.resetForm()
        actions.setSubmitting(false)

        this.recaptchaRef.current.reset()

        this.setState({
          alertColor: 'success',
          alertMessage: this.props.t('RegisterPanel.submitSuccess')
        })
      })
      .catch(({response = {data: {}}}) => {
        const {errors = []} = response.data

        actions.setSubmitting(false)

        this.recaptchaRef.current.reset()

        for (let i = 0; i < errors.length; i++) {
          this.setState({
            alertColor: 'danger',
            alertMessage: this.props.t([
              `RegisterPanel.serverErrors.${errors[i].code}`,
              'RegisterPanel.submitFailure'
            ])
          })
          return
        }

        this.setState({
          alertColor: 'danger',
          alertMessage: this.props.t('RegisterPanel.submitFailure')
        })
      })
  }

  render () {
    const {t} = this.props
    const {alertColor, alertMessage} = this.state

    return (
      <div>
        <Alert color={alertColor}>
          {alertMessage}
        </Alert>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: ''
          }}

          validationSchema={Yup.object().shape({
            firstName: Yup.string().required(t('RegisterPanel.validationErrors.requiredFirstName')),
            lastName: Yup.string().required(t('RegisterPanel.validationErrors.requiredLastName')),
            email: Yup.string().email(t('RegisterPanel.validationErrors.invalidEmail')).required(t('RegisterPanel.validationErrors.requiredEmail'))
          })}

          onSubmit={this.handleSubmit}

          render={({values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit}) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <FormGroup error={errors.firstName} touched={touched.firstName}>
                <Label htmlFor='firstName'>
                  {t('RegisterPanel.firstNameLabel')}
                </Label>
                <Input
                  id='firstName'
                  name='firstName'
                  type='text'
                  value={values.firstName}
                  placeholder={t('RegisterPanel.firstNamePlaceholder')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup error={errors.lastName} touched={touched.lastName}>
                <Label htmlFor='lastName'>
                  {t('RegisterPanel.lastNameLabel')}
                </Label>
                <Input
                  id='lastName'
                  name='lastName'
                  type='text'
                  value={values.lastName}
                  placeholder={t('RegisterPanel.lastNamePlaceholder')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup error={errors.email} touched={touched.email}>
                <Label htmlFor='email'>
                  Email
                </Label>
                <Input
                  id='email'
                  name='email'
                  type='email'
                  value={values.email}
                  placeholder='you@example.com'
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </FormGroup>

              <SubmitButton disabled={isSubmitting}>
                {t('RegisterPanel.submitButton')}
              </SubmitButton>

              <ReCAPTCHA
                ref={this.recaptchaRef}
                onChange={this.handleCaptchaChange}
              />
            </form>
          )}
        />
      </div>
    )
  }
}

export default withNamespaces()(Form)
