import React from 'react'
import PropTypes from 'prop-types'

import Layout from 'components/Layout'
import RegisterPanel from 'components/RegisterPanel'

class IndexPage extends React.Component {
  static propTypes = {
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  }

  handleI18NInitialized = (i18n) => {
    this.props.navigate(`/${i18n.language}`)
  }

  render () {
    const {navigate, location} = this.props

    return (
      <Layout navigate={navigate} location={location} onI18NInitialized={this.handleI18NInitialized}>
        <RegisterPanel />
      </Layout>
    )
  }
}

export default IndexPage
